import styled from 'styled-components'

export const Button = styled.button`
  background: var(--blue);
  box-shadow: 0px 4px 8px rgba(54, 123, 245, 0.24);
  border-radius: 4px;
  padding: 18px 40px;

  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  /* outline: none; */

  :hover {
    background: #518ffb;
    transform: translateY(-2px);
    transition: transform 150ms ease;
  }
`

export const ButtonSpaced = styled(Button)`
  margin: 10px 20px;
`
