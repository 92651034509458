import * as React from 'react'
import styled, { css } from 'styled-components'

import { Section, SectionGray, SectionHeader } from './Section'

interface Step {
  src: any
  text: string
}

interface NumberedSectionProps {
  gray: boolean
  title: React.ReactNode | string
  steps: Step[]
}

const Steps = styled.div<{ numberOfSteps: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.numberOfSteps}, 1fr);

  ${props =>
    props.numberOfSteps === 3 &&
    css`
      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
      }
    `}

  ${props =>
    props.numberOfSteps === 4 &&
    css`
      @media (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 700px) {
        grid-template-columns: 1fr;
      }
    `}

  grid-column-gap: 80px;
  grid-row-gap: 80px;
  text-align: center;
  font-size: 24px;
`

const Step = styled.div``

const StepImage = styled.img`
  margin: auto;
  margin-bottom: 40px;
  height: 200px;
  max-width: 100%;

  @media (max-width: 500px) {
    height: 120px;
  }
`

const StepNumber = styled.div`
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 20px;
`

const StepText = styled.div`
  max-width: 250px;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const NumberedSection = (props: NumberedSectionProps) => {
  const SectionComponent = props.gray ? SectionGray : Section

  return (
    <SectionComponent>
      <SectionHeader>{props.title}</SectionHeader>

      <Steps numberOfSteps={props.steps.length}>
        {props.steps.map((step, i) => (
          <Step key={step.text}>
            <StepImage src={step.src} alt="" />
            <StepNumber>{i + 1}</StepNumber>
            <StepText>{step.text}</StepText>
          </Step>
        ))}
      </Steps>
    </SectionComponent>
  )
}
