import * as React from 'react'
import styled, { keyframes } from 'styled-components'

import { useWindowSize } from '../../hooks/useWindowSize'
import { LanguagePrefix } from '../../language/types'
import { Footer } from '../Footer'
import { Header } from '../header/Header'
// import { Header } from '../header/Header2'
import checks from '../images/checks.svg'
import aquant from '../images/companies/aquant.png'
import checkPoint from '../images/companies/check-point.png'
import generalMotors from '../images/companies/general-motors.png'
import mercedes from '../images/companies/mercedes-benz.png'
import paloAltoNetworks from '../images/companies/palo-alto-networks.png'
import trax from '../images/companies/trax.svg'
import fundGuard from '../images/companies/fund-guard.png'
import theator from '../images/companies/theator.svg'
// import zimperium from '../images/companies/zimperium.png'
import hire from '../images/hire.svg'
import interview from '../images/interview.svg'
import match from '../images/match.svg'
import project from '../images/project.svg'
import resume from '../images/resume.svg'
import select from '../images/select.svg'
import test from '../images/speed-test.svg'
import team from '../images/team.svg'
import gatsby from '../images/technologies/gatsby.svg'
import go from '../images/technologies/go.svg'
import js from '../images/technologies/js.svg'
import mongodb from '../images/technologies/mongodb.svg'
import ng from '../images/technologies/ng.svg'
import node from '../images/technologies/node.svg'
import python from '../images/technologies/python.svg'
import react from '../images/technologies/react.svg'
import ruby from '../images/technologies/ruby.svg'
import vue from '../images/technologies/vue.svg'
import { Nav } from '../Nav'
import { NumberedSection } from '../NumberedSection'
import { Section, SectionGray, SectionHeader } from '../Section'
import { SignUpBottomSection } from '../SignUpBottomSection'

const companies = [
  { name: 'Mercedes Benz', src: mercedes },
  { name: 'Palo Alto Networks', src: paloAltoNetworks },
  { name: 'General Motors', src: generalMotors },
  { name: 'Theator', src: theator },
  { name: 'Trax', src: trax },
  { name: 'Check Point', src: checkPoint },
  { name: 'Aquant', src: aquant },
  { name: 'FundGuard', src: fundGuard },
]

const programmingLanguages = [
  { name: 'React' },
  { name: 'Angular' },
  { name: 'Vue' },
  { name: 'Node.JS' },
  { name: 'Python' },
  { name: 'Ruby' },
  { name: 'Go' },
]

const programmingLanguages2 = [
  { name: 'iOS' },
  { name: 'Android' },
  { name: 'React Native' },
  { name: 'Flutter' },
  { name: 'JavaScript' },
  { name: 'Solidity' },
  { name: 'TensorFlow' },
]

const programmingLanguages3 = [
  { name: 'C++' },
  { name: 'Java' },
  { name: 'AWS' },
  { name: 'Linux' },
  { name: 'C#' },
  { name: 'Elixir' },
  { name: 'Docker' },
]

const programmingLanguageLogos = [
  { src: mongodb, delay: 3000 },
  { src: go, delay: 6000 },
  { src: js, delay: 15000 },
  { src: gatsby, delay: 0 },
  { src: ng, delay: 21000 },
  { src: node, delay: 27000 },
  { src: python, delay: 24000 },
  { src: react, delay: 18000 },
  { src: ruby, delay: 9000 },
  { src: vue, delay: 12000 },
]

const Companies = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 60px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  justify-items: center;
  align-items: center;
`

const CompanyLogo = styled.img`
  min-width: 0;
  max-width: 100%;
  max-height: 50px;

  :hover {
    transform: scale(1.1);
  }
  transition: transform ease 0.2s;
`

const FEATURE_MOBILE_BREAKPOINT = 800

const FeatureSection = styled(Section)``

const FeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${FEATURE_MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  padding: 80px 0;
`

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FeatureHeader = styled.h3`
  font-size: 29px;
  line-height: 40px;
  font-weight: bold;
`

const FeatureText = styled.p`
  font-size: 24px;
  line-height: 33px;
`

const FeatureImage = styled.img<{ imageLeft?: boolean }>`
  max-height: 200px;
  max-width: 100%;
  margin: auto;
  ${props => props.imageLeft && 'order: -1;'}

  @media (max-width: ${FEATURE_MOBILE_BREAKPOINT}px) {
    order: -1;
  }
`

const Feature = (props: FeatureProps & { imageLeft?: boolean; image: any }) => {
  return (
    <FeatureWrapper>
      <FeatureContent>
        <FeatureHeader>{props.header}</FeatureHeader>
        <FeatureText>{props.text}</FeatureText>
      </FeatureContent>
      <FeatureImage src={props.image} alt="" imageLeft={props.imageLeft} />
    </FeatureWrapper>
  )
}

// const IsraelsFinestSection = styled(SectionGray)`
//   padding: 110px 30px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 30px;
//   line-height: 41px;
//   text-align: center;
// `

const ProgrammingLanguages = styled(SectionGray)`
  position: relative;
`

const ProgrammingLanguagesSectionHeader = styled(SectionHeader)`
  position: relative;
  z-index: 2;
`

const ProgrammingLanguagesWrapper = styled.div`
  direction: ltr;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 120px;
  grid-row-gap: 40px;
  font-size: 18px;
  position: relative;
  z-index: 2;

  @media (max-width: 1200px) {
    grid-column-gap: 40px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const ProgrammingLanguagesSection = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 40px 20px;
  border-radius: 5px;
  box-shadow: 5px 5px 12px -6px rgba(0, 0, 0, 0.25);
  text-align: center;
`

const ProgrammingLanguage = styled.div`
  line-height: 1.6;
`

const move = (pageWidth: number, logoWidth: number) => keyframes`
  from {
    transform: translateX(${logoWidth}px);
  }

  to {
    transform: translateX(-${(pageWidth || 2000) + logoWidth}px);
  }
`

const bounce = keyframes`
  from {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(5px);
  }
`

const FloatingLogos = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
`

const TECHNOLOGY_LOGO_WIDTH = 90

interface TechnologyLogoProps {
  delay: number
  top: number
  topMobile: number
  pageWidth: number
}

const TechnologyLogo = styled.div<TechnologyLogoProps>`
  background: #ffffff;
  width: min-content;
  border-radius: 50px;
  box-shadow: 5px 5px 12px -6px rgba(0, 0, 0, 0.25);
  position: absolute;
  animation: ${props => move(props.pageWidth, TECHNOLOGY_LOGO_WIDTH)} 30s ${props => props.delay}ms
    linear infinite;
  right: 0px;
  transform: translateX(${TECHNOLOGY_LOGO_WIDTH}px);
  top: ${props => props.top}px;
  @media (max-width: 600px) {
    top: ${props => props.topMobile}px;
  }
  overflow: hidden;
  height: ${TECHNOLOGY_LOGO_WIDTH}px;
  width: ${TECHNOLOGY_LOGO_WIDTH}px;
`

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
export interface LandingPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

interface FeatureProps {
  header: string
  text: string
}

interface LandingPagePropsWithi18n extends LandingPageProps {
  otherLanguageUrl: string
  languagePrefix: LanguagePrefix
  isClient: boolean
  i18n: {
    trustedBy: string
    howDoesSkilledWork: string | React.ReactNode
    step1: string
    step2: string
    step3: string
    feature1: FeatureProps
    feature2: FeatureProps
    feature3: FeatureProps
    vettingProcess?: {
      step1: string
      step2: string
      step3: string
      step4: string
    }
    joinSkilledCommunity?: string
    hireDeveloper: string
    applyAsDeveloper: string
    applyNow: string
    tagLine: string
    subtitle: string
    israelsFinest: string | React.ReactNode
    hire: string
    apply: string
    contact: string
    aboutUs: string
    blog: string
    otherLanguage: string
  }
}

const LandingPage = (props: LandingPagePropsWithi18n) => {
  const { width } = useWindowSize()

  return (
    <div>
      <Nav
        i18n={props.i18n}
        languagePrefix={props.languagePrefix}
        otherLanguageUrl={props.otherLanguageUrl}
        showCandidateLink={props.isClient}
        showClientLink={!props.isClient}
      />
      <Header i18n={props.i18n} isClient={props.isClient} />

      <Section>
        <SectionHeader>{props.i18n.trustedBy}</SectionHeader>

        <Companies>
          {companies.map(company => (
            <CompanyLogo key={company.name} src={company.src} alt={company.name} />
          ))}
        </Companies>
      </Section>

      <NumberedSection
        gray={false}
        title={props.i18n.howDoesSkilledWork}
        steps={[
          {
            src: select,
            text: props.i18n.step1,
          },
          {
            src: match,
            text: props.i18n.step2,
          },
          {
            src: team,
            text: props.i18n.step3,
          },
        ]}
      />

      <FeatureSection>
        <Feature
          header={props.i18n.feature1.header}
          text={props.i18n.feature1.text}
          image={interview}
        />
        <Feature
          imageLeft
          header={props.i18n.feature2.header}
          text={props.i18n.feature2.text}
          image={test}
        />
        <Feature header={props.i18n.feature3.header} text={props.i18n.feature3.text} image={hire} />
      </FeatureSection>

      <ProgrammingLanguages>
        <FloatingLogos>
          {programmingLanguageLogos.map((logo, i) => (
            <TechnologyLogo
              key={logo.delay}
              delay={logo.delay}
              top={30 + ((i * 70) % 350)}
              topMobile={100 + ((i * 230) % 900)}
              pageWidth={width}
            >
              <img src={logo.src} alt="" />
            </TechnologyLogo>
          ))}
        </FloatingLogos>

        <ProgrammingLanguagesSectionHeader>
          {props.i18n.israelsFinest}
        </ProgrammingLanguagesSectionHeader>

        <ProgrammingLanguagesWrapper>
          <ProgrammingLanguagesSection>
            {programmingLanguages.map(language => (
              <ProgrammingLanguage key={language.name}>{language.name}</ProgrammingLanguage>
            ))}
          </ProgrammingLanguagesSection>
          <ProgrammingLanguagesSection>
            {programmingLanguages2.map(language => (
              <ProgrammingLanguage key={language.name}>{language.name}</ProgrammingLanguage>
            ))}
          </ProgrammingLanguagesSection>
          <ProgrammingLanguagesSection>
            {programmingLanguages3.map(language => (
              <ProgrammingLanguage key={language.name}>{language.name}</ProgrammingLanguage>
            ))}
          </ProgrammingLanguagesSection>
        </ProgrammingLanguagesWrapper>
      </ProgrammingLanguages>

      {props.i18n.vettingProcess && (
        <NumberedSection
          gray={false}
          title={
            <>
              4-STEP VETTING <strong>PROCESS</strong>
            </>
          }
          steps={[
            {
              src: resume,
              text: props.i18n.vettingProcess.step1,
            },
            {
              src: project,
              text: props.i18n.vettingProcess.step2,
            },
            {
              src: interview,
              text: props.i18n.vettingProcess.step3,
            },
            {
              src: checks,
              text: props.i18n.vettingProcess.step4,
            },
          ]}
        />
      )}

      <SignUpBottomSection i18n={props.i18n} />

      <Footer i18n={props.i18n} />
    </div>
  )
}

export default LandingPage
