import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import { LanguagePrefix } from '../language/types'

const MOBILE_BREAKPOINT = 600

const NavWrapper = styled.nav`
  background-color: var(--gray-blue);
  color: var(--off-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  min-height: 90px;
  height: 90px;
  flex: 0 0 90px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    min-height: auto;
    height: auto;
    flex: auto;
  }
`

const Logo = styled(Link)`
  z-index: 1;
  font-size: 30px;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: white;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 20px;
  }
`

const NavLinks = styled.div`
  white-space: nowrap;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 20px;
  }
`

const NavLink = styled(Link)`
  z-index: 1;
  color: var(--off-white);
  text-decoration: none;
  font-size: 18px;
  padding: 0 40px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 10px;
  }

  :hover {
    text-decoration: underline;
  }
`

interface NavProps {
  languagePrefix: LanguagePrefix
  otherLanguageUrl: string
  showClientLink: boolean
  showCandidateLink: boolean
  i18n: {
    hire: string
    apply: string
    otherLanguage: string
  }
}

export const Nav = (props: NavProps) => {
  return (
    <NavWrapper>
      <Logo to="/">Skilled</Logo>

      <NavLinks>
        {props.showClientLink && (
          <NavLink to={`${props.languagePrefix}`}>{props.i18n.hire}</NavLink>
        )}
        {props.showCandidateLink && (
          <NavLink to={`${props.languagePrefix}candidate`}>{props.i18n.apply}</NavLink>
        )}
        <NavLink to={props.otherLanguageUrl}>{props.i18n.otherLanguage}</NavLink>
      </NavLinks>
    </NavWrapper>
  )
}
