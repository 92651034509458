import styled from 'styled-components'

export const Section = styled.div`
  padding: 70px 90px 60px;

  strong {
    font-weight: bold;
  }

  @media (max-width: 600px) {
    padding: 70px 40px 60px;
  }
`

export const SectionGray = styled(Section)`
  background-color: var(--light-gray);
  border: 1px solid var(--gary-dark);
  border-left-width: 0;
  border-right-width: 0;
`

export const SectionHeader = styled.h2`
  font-size: 24px;
  text-align: center;
  margin-bottom: 60px;
`
