import * as React from 'react'
import styled from 'styled-components'
import masterPlan from './master-plan.svg'
import { Button } from '../Button'
import { CLIENT_URL, CANDIDATE_URL } from '../../config'
import { navigate } from 'gatsby'
import { track } from '../../utils/analytics'

const HIDE_IMAGE_BREAK_POINT = 1100

const FEET_HEIGHT = 60

const HeaderWrapper = styled.div`
  display: flex;
  background-color: var(--gray-blue);
  color: var(--off-white);
  justify-content: space-between;
  position: relative;
`

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
  position: relative;
  top: -${FEET_HEIGHT}px;
  min-height: 530px;

  @media (max-width: ${HIDE_IMAGE_BREAK_POINT}px) {
    top: 0;
  }

  @media (max-width: 450px) {
    padding: 50px 15px;
  }
`

const HeroText = styled.h1`
  font-weight: 600;
  font-size: 42px;
  line-height: 57px;
  text-align: center;
  color: var(--off-white);
  margin-bottom: 30px;
  max-width: 590px;
`

const SubtitleText = styled.h2`
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: var(--off-white);
  margin-bottom: 70px;
  max-width: 700px;
`

const CTAButton = styled(Button)`
  max-width: 90%;
`

const HeroImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* height: 620px; */
  width: 50%;

  @media (max-width: ${HIDE_IMAGE_BREAK_POINT}px) {
    width: 0;
  }

  /* to position this above WhiteBottom which has position: absolute */
  opacity: 1;
  z-index: 1;
`

const HeroImage = styled.img`
  animation: 1.5s ease 1 fade-in;
  width: 100%;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

// so that the feet stand on the white
const WhiteBottom = styled.div`
  position: absolute;
  bottom: 0;
  background-color: white;
  height: ${FEET_HEIGHT}px;
  width: 100%;

  @media (max-width: ${HIDE_IMAGE_BREAK_POINT}px) {
    display: none;
  }
`

interface HeaderProps {
  isClient: boolean
  i18n: {
    tagLine: string
    subtitle: string
    hireDeveloper: string
    applyNow: string
  }
}

export const Header = (props: HeaderProps) => {
  return (
    <HeaderWrapper>
      <TextWrapper>
        <HeroText>{props.i18n.tagLine}</HeroText>
        <SubtitleText>{props.i18n.subtitle}</SubtitleText>
        {props.isClient ? (
          <CTAButton
            onClick={() => {
              track('Client - header - hire')
              navigate(CLIENT_URL)
            }}
          >
            {props.i18n.hireDeveloper}
          </CTAButton>
        ) : (
          <CTAButton
            onClick={() => {
              track('Candidate - header - apply')
              navigate(CANDIDATE_URL)
            }}
          >
            {props.i18n.applyNow}
          </CTAButton>
        )}
      </TextWrapper>

      <HeroImageWrapper>
        <HeroImage src={masterPlan} alt="" />
      </HeroImageWrapper>

      <WhiteBottom />
    </HeaderWrapper>
  )
}
