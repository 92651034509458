import * as React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { CANDIDATE_URL, CLIENT_URL } from '../config'
import { ButtonSpaced } from './Button'
import { Section } from './Section'

const SignUpBottomSectionWrapper = styled(Section)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  min-height: 200px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`

const JoinBottomText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.75px;
  padding: 0 20px;
  text-align: center;
`

const JoinBottomTextMain = styled(JoinBottomText)`
  @media (max-width: 1000px) {
    padding-bottom: 30px;
    width: 100%;
  }
`

interface SignUpBottomSectionProps {
  i18n: {
    joinSkilledCommunity?: string
    hireDeveloper: string
    applyAsDeveloper: string
  }
}

export const SignUpBottomSection = (props: SignUpBottomSectionProps) => (
  <SignUpBottomSectionWrapper>
    {props.i18n.joinSkilledCommunity ? (
      <JoinBottomTextMain>{props.i18n.joinSkilledCommunity}</JoinBottomTextMain>
    ) : null}
    <ButtonsWrapper>
      <ButtonSpaced
        onClick={() => {
          navigate(CLIENT_URL)
        }}
      >
        {props.i18n.hireDeveloper}
      </ButtonSpaced>
      <ButtonSpaced
        onClick={() => {
          navigate(CANDIDATE_URL)
        }}
      >
        {props.i18n.applyAsDeveloper}
      </ButtonSpaced>
    </ButtonsWrapper>
  </SignUpBottomSectionWrapper>
)
