import * as React from 'react'
import styled from 'styled-components'

import { CANDIDATE_URL, CLIENT_URL, CONTACT_EMAIL } from '../config'

const FooterWrapper = styled.footer`
  background-color: var(--gray-blue);
  color: var(--off-white);
  align-items: center;
  justify-content: center;
  padding: 30px;
`

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  @media (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
`

const FooterLink = styled.a`
  padding: 0 10px;
  color: var(--off-white);
  text-decoration: none;
  text-align: center;

  :hover {
    text-decoration: underline;
  }
`

const Copyright = styled.div`
  text-align: center;
`

interface FooterProps {
  i18n: {
    contact: string
    hire: string
    apply: string
    aboutUs: string
    blog: string
  }
}

export const Footer = (props: FooterProps) => {
  return (
    <FooterWrapper>
      <Links>
        <FooterLink href={CLIENT_URL}>{props.i18n.hire}</FooterLink>
        <FooterLink href={CANDIDATE_URL}>{props.i18n.apply}</FooterLink>
        <FooterLink href="/about-us">{props.i18n.aboutUs}</FooterLink>
        <FooterLink href="/blog/why-freelance">{props.i18n.blog}</FooterLink>
        <FooterLink href="/survey">2020 Survey</FooterLink>
        <FooterLink href={`mailto:${CONTACT_EMAIL}`} target="_blank">
          {props.i18n.contact}
        </FooterLink>
      </Links>
      <Copyright>Skilled © {new Date().getFullYear()}</Copyright>
    </FooterWrapper>
  )
}
